<div
  *ngIf="(activeBanner$|async) as banner"
  [@banner]
  [class.isError]="banner.type==='ERROR'"
  class="content-wrapper"
>
  <ng-container *ngIf="banner.progress$ && (banner.progress$|async) as progress">
    <mat-progress-bar
      *ngIf="progress>0"
      mode="determinate"
      [value]="progress"
    ></mat-progress-bar>
  </ng-container>

  <div class="inner-content-wrapper">
    <div
      *ngIf="banner.img"
      class="motivational-img-wrapper"
    >
      <img [src]="banner.img" />
    </div>

    <div class="message-wrapper">
      <mat-icon *ngIf="banner.ico">{{banner.ico}}</mat-icon>
      <mat-icon
        *ngIf="banner.svgIco"
        [svgIcon]="banner.svgIco"
      ></mat-icon>

      <div
        [innerHTML]="banner.msg|translate:banner.translateParams"
        class="message"
      ></div>
    </div>

    <div class="buttons">
      <button
        (click)="dismiss(banner.id)"
        *ngIf="!banner.action2 && !banner.isHideDismissBtn"
        color="primary"
        mat-button
        tabindex="1"
      >
        {{T.G.DISMISS|translate}}
      </button>

      <button
        (click)="action(banner.id, banner.action)"
        *ngIf="banner.action"
        color="primary"
        mat-button
        tabindex="1"
      >
        {{banner.action.label|translate:banner.translateParams}}
      </button>
      <button
        (click)="action(banner.id, banner.action2)"
        *ngIf="banner.action2"
        color="primary"
        mat-button
        tabindex="1"
      >
        {{banner.action2.label|translate:banner.translateParams}}
      </button>
      <button
        (click)="action(banner.id, banner.action3)"
        *ngIf="banner.action3"
        color="primary"
        mat-button
        tabindex="1"
      >
        {{banner.action3.label|translate:banner.translateParams}}
      </button>
    </div>
  </div>
</div>
